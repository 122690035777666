<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogDownloadRegister" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Download Register</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date_end" label="End Date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_end" @input="menu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="cetakReportRegister()">
            Cetak Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="rounded-lg" outlined>
      <v-dialog v-model="dialogDelete">
        <v-card>

          <v-toolbar flat>
            <v-toolbar-title>Detail Bukti Potong PPh</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-row no-gutters class="px-5">
            <v-col :cols="4">
              <v-card ref="form" class="my-5">
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Bukti Potong</v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-text-field v-model="dokumenBupot.bupot_number" label="Nomor Dok. BuktiPotong" filled readonly
                    class="mt-5"></v-text-field>
                  <v-text-field v-model="dokumenBupot.bupot_date" label="Tanggal Bukti Potong" filled readonly>
                  </v-text-field>
                  <vuetify-money v-model="dokumenBupot.dpp_amount" label="Jumlah Penghasilan Bruto"
                    v-bind:placeholder="placeholder" readonly disabled v-bind:outlined="outlined"
                    v-bind:clearable="clearable" v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                  <vuetify-money v-model="dokumenBupot.percentage" label="Tarif" v-bind:placeholder="placeholder"
                    readonly disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                  <vuetify-money v-model="dokumenBupot.pph_amount" label="PPh" v-bind:placeholder="placeholder" readonly
                    disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="8">
              <v-card class="my-5 mx-2">
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Kwitansi</v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-data-table :headers="headersKwitansi" :items="dokumenKwt" sort-by="calories"
                    class="elevation-1 my-5">
                    <template v-slot:[`item.dpp_amount`]="{ item }">
                      {{ formatCurrency(item.dpp_amount) }}
                    </template>
                    <template v-slot:[`item.ppn_amount`]="{ item }">
                      {{ formatCurrency(item.ppn_amount) }}
                    </template>
                    <template v-slot:[`item.pph_amount`]="{ item }">
                      {{ formatCurrency(item.pph_amount) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-card-title>
        Master Inquiry
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mx-1 mt-1">
        <v-col cols="2" sm="2" md="2" lg="2">
          <v-text-field v-model="cariData.nomor_bupot" label="Nomor Refund" outlined dense>
          </v-text-field>
        </v-col>
        <v-col cols="4" sm="4" md="4" lg="4">
          <v-text-field v-model="cariData.customer_id" label="Customer Name" outlined dense>
          </v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <!-- <v-text-field v-model="cariData.tanggal_awal" single-line label="Dari Tanggal" readonly>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_awal" />
            </template>
          </v-text-field> -->
          <v-menu v-model="menuTanggalAwal" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_awal" label="Start Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_awal" @input="menuTanggalAwal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <!-- <v-text-field v-model="cariData.tanggal_akhir" single-line label=" Sampai Tanggal" readonly>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_akhir" />
            </template>
          </v-text-field> -->
          <v-menu v-model="menuTanggalAkhir" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_akhir" label="End Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_akhir" @input="menuTanggalAkhir = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mx-1 my-1">
        <v-col>
          <v-btn color="success" dark class="mb-2" @click="searchData">
            Cari Data
            <v-icon dark right>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="red" dark class="mb-2 ml-2" @click="resetData">
            Reset Data
            <v-icon dark right>
              mdi-minus-circle
            </v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col align="right">
          <v-btn color="primary" dark class="mb-2" @click="showDialogRegister()">
            Download Register
          </v-btn>
        </v-col>
      </v-row>
      <img id='barcode3' style="display:none;" />
      <v-divider></v-divider>
      <v-data-table v-model="selected" :headers="headers" :items="inquiryBupot" item-key="bupot_number"
        class="elevation-1" :loading="loading" loading-text="Fetching Data...">
        <template v-slot:[`item.status`]="{ item }">
          <div class="font-weight-bold">
            {{ formatValidasi(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.status_refund`]="{ item }">
          <v-chip :color="getColor(item.status_refund)" dark>
            {{ item.status_refund }}
          </v-chip>
        </template>
        <template v-slot:[`item.bupot_date`]="{ item }">
          {{ formatDate(item.bupot_date) }}
        </template>
        <template v-slot:[`item.dpp_amount`]="{ item }">
          {{ formatCurrency(item.dpp_amount) }}
        </template>
        <template v-slot:[`item.percentage`]="{ item }">
          {{ formatCurrency(item.percentage) }}
        </template>
        <template v-slot:[`item.pph_amount`]="{ item }">
          {{ formatCurrency(item.pph_amount) }}
        </template>
        <template v-slot:[`item.refund_number`]="{ item }">
          <!-- <router-link :to="{name: 'DetailBupot', params: { id: value }}"> -->
          <a @click="showPdf(item)" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <!-- <a @click="showPdf(item)" v-if="item.status === 'S'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>    
        <a @click="showPdf(item)" v-else-if="item.status === 'V'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>              
        <a @click="showPdf(item)" v-else-if="item.status === 'D'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>  
        <a @click="showPdfReject(item)" v-else-if="item.status === 'R'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>  
        <a @click="showPdfCancel(item)" v-else-if="item.status === 'C'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>    
        <a @click="showPdf(item)" v-else-if="item.status === 'E'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>                    -->
          <!-- </router-link> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- <h5>Selected: {{selected}}</h5>
  <h5>Selected: {{selected2}}</h5> -->

  </v-container>
</template>

<script>
import DatePicker from "../../../components/DatePicker.vue";
import axios from 'axios'
import { mapGetters } from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import JsBarcode from 'jsbarcode';
export default {
  data() {
    return {
      label: "Value",
      placeholder: " ",
      overlay: false,
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "0",
      options: {
        locale: "pt-BR",
        prefix: "IDR",
        suffix: "",
        length: 11,
        precision: 0
      },
      loading: false,
      dialogDelete: false,
      dokumenBupot: {},
      custid2: 0,
      cariData: [],
      search: '',
      dialog: false,
      singleSelect: false,
      dialogDownloadRegister: false,
      menuTanggalAwal: false,
      menuTanggalAkhir: false,
      menu2: false,
      menu3: false,
      date: '',
      date_end: '',
      selected: [],
      selected2: [],
      inquiryBupot: [],
      selectedIndex: -1,
      selectedItem: {},
      dokumenKwtarray: [],
      dokumenKwtarray2: [],
      dokumenKwtarray3: [],
      dokumenKwt: [],
      opacity: 0.5,
      headers: [
        { text: 'Status', value: 'status' },
        { text: 'Nomor Refund/BuPot', value: 'refund_number' },
        { text: 'Tanggal Input', value: 'refund_date' },
        { text: 'Tanggal Jatuh Tempo', value: 'bupot_giro_date' },
        { text: 'DPP', value: 'dpp_amount' },
        { text: 'PPh', value: 'pph_amount' },
        { text: 'Jumlah Kwitansi', value: 'JumlahKwitansi' },
        { text: 'Status PPH', value: 'status_pph' },
        { text: 'Status Refund', value: 'status_refund' },
      ],
      headersKwitansi: [
        { text: 'Nomor Kwitansi', value: 'kwt_number' },
        { text: 'Tanggal Kwitansi', value: 'kwt_date' },
        { text: 'Jenis Kwitansi', value: 'kwt_type' },
        { text: 'DPP Kwitansi', value: 'dpp_amount' },
        { text: 'PPN Kwitansi', value: 'ppn_amount' },
        { text: 'PPh Kwitansi', value: 'pph_amount' },
      ],
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Role' : 'Edit Role'
    },
    ...mapGetters({
      user: 'user'
    })
  },
  mounted() {
    // if (localStorage.getItem('reloaded')) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem('reloaded');
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem('reloaded', '1');
    //   location.reload();
    // }      
  },
  created() {
    this.loading = true;
    let uri = `trxpagekasir`;
    axios.get(uri).then(response => {
      this.inquiryBupot = response.data.data;
      this.loading = false;
      // console.log(this.inquiryBupot);
    });
  },
  methods: {
    getColor(item) {
      if (item === 'Input Refund') {
        return 'primary';
      } else {
        return 'green';
      }
    },
    formatValidasi(value) {
      if (value == "D") {
        return "Draft"
      } else if (value == "S") {
        return "Submit"
      } else if (value == "V") {
        return "Valid"
      } else if (value == "R") {
        return "Reject"
      } else if (value == "C") {
        return "Cancel"
      } else if (value == "E") {
        return "Edit"
      } else if (value == "T") {
        return "Completed"
      }
    },
    showBupotid(a) {
      this.selectedIndex = this.inquiryBupot.indexOf(a);
      this.selectedItem = Object.assign({}, a);
      // alert(a);
    },
    searchData() {
      if (typeof (this.cariData.tanggal_awal) !== 'undefined' && typeof (this.cariData.tanggal_akhir) !== 'undefined') {
        var d1 = new Date(this.cariData.tanggal_awal)
        var d2 = new Date(this.cariData.tanggal_akhir)
        if (d1 < d2) {
          this.loading = true;
          axios({
            method: 'post',
            url: 'searchdatakasir',
            data: {
              nomor_bupot: this.cariData.nomor_bupot,
              tanggal_awal: this.cariData.tanggal_awal,
              tanggal_akhir: this.cariData.tanggal_akhir,
              customer_id: this.cariData.customer_id
            },
          })
            .then(response => {
              this.inquiryBupot = response.data.data;
              this.loading = false;
              // window.location.reload();
            })
            .catch(error => {
              console.log(error.response)

            })
        } else {
          this.$swal.fire(
            'Peringatan!',
            'Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal',
            'warning'
          )
        }
      } else {
        this.$swal.fire(
          'Peringatan!',
          'Harap Isi Semua Tanggal!',
          'warning'
        )
      }
      // if (this.cariData.tanggal_awal > this.cariData.tanggal_akhir) {
      //   this.$swal.fire(
      //     'Peringatan!',
      //     'Tanggal awal harus lebih kecil dari tanggal akhir!!',
      //     'warning'
      //   )
      // } else {
      //   this.loading = true;
      //   axios({
      //     method: 'post',
      //     url: 'searchdatakasir',
      //     data: {
      //       nomor_bupot: this.cariData.nomor_bupot,
      //       tanggal_awal: this.cariData.tanggal_awal,
      //       tanggal_akhir: this.cariData.tanggal_akhir,
      //       customer_id: this.cariData.customer_id
      //     },
      //   })
      //     .then(response => {
      //       this.inquiryBupot = response.data.data;
      //       this.loading = false;
      //       // window.location.reload();
      //     })
      //     .catch(error => {
      //       console.log(error.response)

      //     })
      // }
    },
    resetData() {
      this.loading = true;
      this.cariData = [];
      let uri = `trxpagekasir`;
      axios.get(uri).then(response => {
        this.inquiryBupot = response.data.data;
        this.loading = false;
      });
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
      });

      return formatter.format(value); /* $2,500.00 */
    },
    showPdf(item) {
      /* eslint-disable */
      var watermark = '';
      this.overlay = true;
      if (item.status === 'V') {
        watermark = 'VALIDATED'
      } else if (item.status === 'T') {
        watermark = 'COMPLETED'
      } else if (item.status === 'R') {
        watermark = 'REJECTED'
      } else if (item.status === 'C') {
        watermark = 'CANCEL'
      }
      var today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      var hour = today.getHours();
      today = formattedDate;
      var currentHour = hour + ':' + minutes + ':' + seconds;
      JsBarcode("#barcode3", item.refund_number);
      this.dokumenKwtarray3.splice(0);
      var sum = 0;
      var sumb = 0;
      var sumc = 0;
      var i = 1;
      var sumTotalDppRefund = 0;
      var sumTotalPPhRefund = 0;
      let uri4 = `dtltrxpage/${item.refund_id}`;
      axios.get(uri4).then(response => {
        this.dokumenBupot = response.data.data;
        this.custid2 = this.dokumenBupot;
        axios({
          method: 'post',
          url: 'getUserIdbyCustId',
          data: {
            customer_id: this.custid2
          },
        })
          .then(response => {
            // window.location.reload();
            this.varUserId = response.data.data;
            axios({
              method: 'post',
              url: 'getSuppSiteNameByUserId',
              data: {
                user_id: this.varUserId
              },
            })
              .then((response) => {
                this.supplier_site_name = response.data.data;
                //  alert(response.data.data);
                let uri5 = `dtlKwtpage/${item.refund_id}/${this.custid2}`;
                axios.get(uri5).then(response => {
                  this.dokumenKwt = response.data.data;
                  let uri = `getcustnumberandname/${this.custid2}`;
                  axios.get(uri).then(response => {
                    this.customer = response.data.data;
                    let uri2 = `getkwtarray/${item.refund_id}/${this.custid2}`;
                    axios.get(uri2).then(response => {
                      this.dokumenKwtarray = response.data.data;
                      try {
                        this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
                      }
                      catch (err) {
                        console.log(err);
                      }
                      this.data = this.dokumenKwtarray
                      this.data.forEach(item => {
                        this.dokumenKwtarray3.push({
                          bupot_number: item.bupot_number,
                          bupot_date: item.bupot_date,
                          dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
                          pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
                          kwt_id: item.kwt_id,
                          kwt_number: item.kwt_number,
                          kwt_date: item.kwt_date,
                          dpp_amount: this.formatCurrency2(item.dpp_amount),
                          ppn_amount: this.formatCurrency2(item.ppn_amount),
                          pph_amount: this.formatCurrency2(item.pph_amount2),
                          tarif: item.tarif
                        })
                      })
                      this.dokumenKwtarray.forEach(item => {
                        sum = sum + parseInt(item.dpp_amount);
                      })
                      this.dokumenKwtarray.forEach(item => {
                        sumb = sumb + parseInt(item.ppn_amount);
                      })
                      this.dokumenKwtarray.forEach(item => {
                        sumc = sumc + parseInt(item.pph_amount2);
                      })
                      sum = this.formatCurrency2(sum);
                      sumb = this.formatCurrency2(sumb);
                      sumc = this.formatCurrency2(sumc);
                      sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
                      sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
                      try {
                        this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
                      }
                      catch (err) {
                        console.log(err);
                      }
                      var raw = this.dokumenKwtarray3
                      var body = []
                      var bupotIdonly = []
                      var newIdbupotonly = []
                      var index = 1;
                      var count = 0;
                      var counts = [];
                      var indikator = 1;
                      var activeBupot = "";
                      raw.forEach(item => [
                        bupotIdonly.push({
                          bupotNumber: item[0]
                        })
                      ])
                      newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
                      newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                      var j = 0;
                      for (i = 0; i < raw.length; i++) {
                        var row = []
                        var nextRow = []
                        for (var key in raw[i]) {
                          row.push(raw[i][key])
                        }
                        var no_bupot = row[0];
                        var tanggal_bupot = row[1];
                        var total_dpp_bupot = row[6];
                        var total_pph_bupot = row[8];
                        var no_kwitansi = row[4];
                        var tgl_kwitansi = row[5];
                        var tarif = row[9];
                        row.shift();
                        row.shift();
                        row.shift();
                        row.shift();
                        row.shift();
                        row.shift();
                        row.unshift({
                          content: tgl_kwitansi,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.unshift({
                          content: no_kwitansi,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.unshift({
                          content: index,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.push({
                          content: no_bupot,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.push({
                          content: tarif,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.push({
                          content: total_dpp_bupot,
                          styles: { valign: 'middle', halign: 'right' },
                        })
                        row.push({
                          content: total_pph_bupot,
                          styles: { valign: 'middle', halign: 'right' },
                        })
                        row.push({
                          content: tanggal_bupot,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.splice(3, 4);
                        // for (var key2 in raw[i+1]){
                        //   nextRow.push(raw[i+1][key2])
                        // }
                        // if(activeBupot != row[0]) {
                        //   indikator = 1;
                        // } 
                        // if (indikator === 1){
                        //   activeBupot = row[0];
                        //   indikator = 0;
                        //   count = counts[activeBupot];
                        //   j=0;
                        // }
                        // if (j % count === 0){
                        //   var no_bupot = row[0];
                        //   var tanggal_bupot = row[1];
                        //   var total_dpp_bupot = row[2];
                        //   var total_pph_bupot = row[3];              
                        //   row.shift();
                        //   row.shift();
                        //   row.shift();
                        //   row.shift();
                        //   row.unshift({
                        //     rowSpan: count,
                        //     content: total_pph_bupot,
                        //     styles: { valign: 'middle', halign: 'center' },
                        //   })
                        //   row.unshift({
                        //     rowSpan: count,
                        //     content: total_dpp_bupot,
                        //     styles: { valign: 'middle', halign: 'center' },
                        //   })
                        //   row.unshift({
                        //     rowSpan: count,
                        //     content: tanggal_bupot,
                        //     styles: { valign: 'middle', halign: 'center' },
                        //   })
                        //   row.unshift({
                        //     rowSpan: count,
                        //     content: no_bupot,
                        //     styles: { valign: 'middle', halign: 'center' },
                        //   })
                        //   row.unshift({
                        //     rowSpan: count,
                        //     content: index,
                        //     styles: { valign: 'middle', halign: 'center' },
                        //   })
                        //   j=j+1;
                        //   index = index + 1;
                        // }
                        // else{
                        //   row.shift();
                        //   row.shift();
                        //   row.shift();
                        //   row.shift();
                        //   j=j+1
                        // }
                        index = index + 1;
                        body.push(row);
                      }
                      // for (i = 0; i < raw.length; i++) {
                      //   console.log("data ke",i)
                      //   var row = []
                      //   var nextRow = []
                      //   for (var key in raw[i]) {
                      //     row.push(raw[i][key])
                      //   }
                      //   for (var key2 in raw[i+1]){
                      //     nextRow.push(raw[i+1][key2])
                      //   }
                      //   if(activeBupot != row[0]) {
                      //     indikator = 1;
                      //   } 
                      //   if (indikator === 1){
                      //     activeBupot = row[0];
                      //     indikator = 0;
                      //     count = counts[activeBupot];
                      //     j=0;
                      //   }
                      //   if (j % count === 0){
                      //     var no_bupot = row[0];
                      //     var tanggal_bupot = row[1];
                      //     var total_dpp_bupot = row[2];
                      //     var total_pph_bupot = row[3];              
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: total_pph_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: total_dpp_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: tanggal_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: no_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: index,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     j=j+1;
                      //     index = index + 1;
                      //   }
                      //   else{
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     j=j+1
                      //   }
                      //   body.push(row);
                      //   console.log(body)
                      // }
                      const img = document.querySelector('img#barcode3');
                      const logo = require('@/assets/indogrosir.png')
                      var imgLogo = new Image()
                      imgLogo.src = logo
                      // var img3 = new Image();
                      // img3.url = '../../../assets/indogrosir.png';
                      var cust_name = this.customer.map(({ customer_name }) => customer_name)
                      var cust_number = this.customer.map(({ customer_number }) => customer_number)
                      var header = cust_name;
                      var tglKembali = this.formatDate2(item.bupot_giro_date);
                      if (item.bupot_giro_date == null) {
                        tglKembali = null;
                      }
                      var validationDate = this.formatDate2(item.validation_date);
                      if (item.validation_date == null) {
                        validationDate = null;
                      }
                      // doc.text(header, 13, 5, { baseline: 'middle' });
                      var doc = new jsPDF();
                      // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
                      doc.setFont("helvetica", "bold");
                      doc.getFontList();
                      if (item.status === 'T' || item.status === 'V') {
                        // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        if (item.status_refund === 'Input Refund') {
                          doc.text("TANDA TERIMA REFUND BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else {
                          doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        }
                      } else {
                        if (item.status_refund === 'Input Refund') {
                          doc.text("TANDA TERIMA REFUND BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else {
                          doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        }
                      }
                      doc.setFont("helvetica", "normal");
                      doc.setFontSize(10);
                      doc.addImage(img.src, 'JPEG', 80, 40, 50, 25);
                      doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
                      doc.text("Nomor TT : " + item.refund_number + ' / ' + item.status_pph, 80, 38, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(7);
                      doc.text("Supplier", 155, 7, { align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(7);
                      doc.text(header, 155, 12, { align: 'left', lineHeightFactor: '0.5' });
                      doc.text(this.supplier_site_name, 155, 17, { align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(9);
                      doc.autoTable({
                        columnStyles: {
                          0: { cellWidth: 10 },
                          1: { cellWidth: 34 },
                          2: { cellWidth: 25 },
                          3: { cellWidth: 30 },
                          4: { cellWidth: 16 },
                          5: { cellWidth: 30 },
                          6: { cellWidth: 30 },
                          7: { cellWidth: 25 }
                        },
                        headStyles: {
                          lineWidth: 0.5,
                          lineColor: [0, 0, 0],
                          fillColor: [255, 255, 255],
                          textColor: 'black'
                        },
                        theme: 'grid',
                        head: [[
                          { content: 'No ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nomor Invoice / Kwitansi ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tgl Kwitansi ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nomor Bupot ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tarif(%) ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nilai (Rp.) ', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tgl BuPot ', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                        ], [
                          { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                        ]],
                        bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                        startY: 70,
                        margin: { top: 5, left: 5, right: 2 },
                        body: body,
                        footStyles: {
                          lineWidth: 0.5,
                          lineColor: [0, 0, 0],
                          fillColor: [255, 255, 255],
                          textColor: 'black'
                        },
                        rowPageBreak: 'auto'
                      })
                      let finalY = doc.lastAutoTable.finalY;
                      doc.setFont("helvetica", "bold");
                      doc.autoTable({
                        columnStyles: {
                          0: { cellWidth: 115 },
                          1: { cellWidth: 30 },
                          2: { cellWidth: 30 },
                          3: { cellWidth: 25 },
                        },
                        bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                        body: [[
                          { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                          { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                          { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
                        ]],
                        margin: { top: 5, left: 5, right: 2 },
                        startY: finalY,
                        lineColor: [0, 0, 0]
                      })
                      finalY = doc.lastAutoTable.finalY;
                      finalY = finalY + 10; // The y position on the page
                      if (item.status === 'T' || item.status === 'V') {
                        doc.setFont("helvetica", "bold");
                        doc.text(5, finalY, "Catatan");
                        finalY = finalY + 5;
                        doc.setFont("helvetica", "normal");
                        doc.text(5, finalY, "\u2022 " + "Di-validasi pada tanggal " + validationDate);
                        finalY = finalY + 5;
                        if (item.status === 'V') {
                          doc.text(5, finalY, "\u2022 " + "Tanggal Pembayaran Refund PPh :" + tglKembali);
                          finalY = finalY + 5;
                        }
                      } else {
                        doc.setFont("helvetica", "bold");
                        doc.text(5, finalY, "Catatan");
                        finalY = finalY + 5;
                        doc.setFont("helvetica", "normal");
                        doc.text(5, finalY, "\u2022 " + "Dicetak pada tanggal " + today + " pukul " + currentHour);
                        finalY = finalY + 5;
                        doc.text(5, finalY, "\u2022 " + "Mohon melampirkan BuPot, fotocopy Kwitansi dan fotocopy Faktur Pajak");
                        finalY = finalY + 5;
                      }
                      // doc.autoTable({ 
                      //     columnStyles: {
                      //       0: {cellWidth:8},
                      //       1: {cellWidth:30, fontStyle: 'bold', fontSize: 9}, 
                      //       2: {halign:'center', cellWidth:20, fontSize: 8},
                      //       3: {cellWidth:20, fontSize: 9,halign:'right'},
                      //       4: {cellWidth:20 , fontSize: 9,halign:'right'},
                      //       5: {halign:'center', cellWidth:30, fontSize: 9,fontStyle: 'bold'},
                      //       6: {halign:'center', fontSize: 8,cellWidth:20},
                      //       7: {halign:'right', cellWidth:20, fontSize: 9},
                      //       8: {halign:'right', cellWidth:20, fontSize: 9},
                      //       9: {halign:'right', cellWidth:20, fontSize: 9},
                      //       10:{halign:'right', cellWidth:20, fontSize: 9},
                      //     }, 
                      //     bodyStyles : {lineColor: [0, 0 ,0 ]},
                      //     headStyles: {
                      //         lineWidth: 0.5,
                      //         lineColor: [0, 0, 0],
                      //         fillColor: [255, 255, 255],
                      //         textColor:'black'
                      //     },
                      //     footStyles: {
                      //         lineWidth: 0.5,
                      //         lineColor: [0, 0, 0],
                      //         fillColor: [255, 255, 255],
                      //         textColor:'black'
                      //     },                 
                      //     theme: 'grid',
                      //     head: [[
                      //       {content: 'No ',rowSpan:2,styles: {halign: 'center',valign:'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Bupot ', colSpan: 4,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Kwitansi', colSpan: 5, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
                      //     ],[
                      //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPN', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
                      //     ],
                      //     ],
                      //     body: body,
                      //     margin: {top: 90, left: 1, right: 1},
                      //     foot: [[
                      //       {content: 'Grand total Bupot', colSpan: 3, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: sumTotalDppRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumTotalPPhRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: 'Grand total Kwt', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: sum, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumb, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumc, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}}
                      //     ]]
                      //   })
                      if (item.status !== 'T' && item.status !== 'V') {
                        //   doc.autoTable({
                        //   bodyStyles : {lineColor: [0, 0 ,0 ]},
                        //   headStyles: {
                        //       lineWidth: 0.5,
                        //       lineColor: [0, 0, 0]
                        //   },             
                        //   theme : 'grid',
                        //   head: [[{content: 'Validation Notes', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}]],
                        //   body: [
                        //     ['Tgl. Validasi Refund:  ', validationDate],
                        //     ['Tgl. Cetak  Refund:  ', today],
                        //     ['Tgl. Kembali:  ', tglKembali],
                        //     ['Paraf Petugas Validasi:  ', '                          '],
                        //     ['Nama Petugas:  ', '                          '],
                        //   ],
                        //   margin: {left: 135},
                        //   startY: 215,
                        //   lineColor: [0,0,0]
                        // })
                        if (item.status === 'T' || item.status === 'V') {
                          // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                          doc.autoTable({
                            bodyStyles: { lineColor: [0, 0, 0] },
                            headerStyles: {
                              lineWidth: 0,
                              lineColor: [0, 0, 0]
                            },
                            theme: 'grid',
                            head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                            body: [
                              ['Tgl. Validasi Refund:  ', validationDate],
                              ['Tgl. Pembayaran Refund:  ', '                          '],
                              ['Paraf Petugas Validasi:  ', '                          '],
                              ['Nama Petugas:  ', '                          '],
                            ],
                            margin: { left: 135 },
                            startY: finalY,
                            lineColor: [0, 0, 0]
                          })
                        } else {
                          if (item.status_refund === 'Input Refund') {
                            doc.autoTable({
                              bodyStyles: { lineColor: [0, 0, 0] },
                              headerStyles: {
                                lineWidth: 0,
                                lineColor: [0, 0, 0]
                              },
                              theme: 'grid',
                              head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                              body: [
                                ['Tgl. Validasi Refund:  ', validationDate],
                                ['Tgl. Pembayaran Refund:  ', '                          '],
                                ['Paraf Petugas Validasi:  ', '                          '],
                                ['Nama Petugas:  ', '                          '],
                              ],
                              margin: { left: 135 },
                              startY: finalY,
                              lineColor: [0, 0, 0]
                            })
                          } else {
                            doc.autoTable({
                              bodyStyles: { lineColor: [0, 0, 0] },
                              headerStyles: {
                                lineWidth: 0,
                                lineColor: [0, 0, 0]
                              },
                              theme: 'grid',
                              head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                              body: [
                                ['Tgl. Validasi Refund:  ', validationDate],
                                ['Paraf Petugas Validasi:  ', '                          '],
                                ['Nama Petugas:  ', '                          '],
                              ],
                              margin: { left: 135 },
                              startY: finalY,
                              lineColor: [0, 0, 0]
                            })
                          }
                        }
                      }
                      if (item.status === 'V') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'T') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 40, 250, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'R') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'C') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      }
                      // var totalPages = doc.internal.getNumberOfPages();
                      // for (i = 1; i <= totalPages; i++) {
                      //   doc.setPage(i);
                      //   doc.saveGraphicsState();
                      //   doc.setGState(new doc.GState({opacity: 0.2}));
                      //   doc.setFontSize(100)
                      //   doc.setTextColor(255, 0, 0);
                      //   doc.text(watermark, 55, 200, null, 45)
                      //   doc.restoreGraphicsState();  
                      // }
                      // doc.saveGraphicsState();
                      // doc.setGState(new doc.GState({opacity: 0.2}));
                      // doc.setFontSize(100)
                      // doc.setTextColor(255, 0, 0);
                      // doc.text(watermark, 55, 200, null, 45)
                      // doc.restoreGraphicsState();              
                      doc.setProperties({
                        title: "Laporan Bukti Potong"
                      });
                      this.overlay = false;
                      window.open(doc.output('bloburl'))
                    });
                  });
                });

              })
              .catch(error => {
                console.log(error.response)
              })
          })
          .catch(error => {
            console.log(error.response)
          })
      });
      this.dokumenKwtarray2.splice(0);
    },
    showPdfReject(item) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + '-' + mm + '-' + yyyy;
      JsBarcode("#barcode3", item.refund_number);
      this.dokumenKwtarray3.splice(0);
      var sum = 0;
      var sumb = 0;
      var sumc = 0;
      var i = 1;
      var sumTotalDppRefund = 0;
      var sumTotalPPhRefund = 0;
      let uri4 = `dtltrxpage/${item.refund_id}`;
      axios.get(uri4).then(response => {
        this.dokumenBupot = response.data.data;
        this.custid2 = this.dokumenBupot;
        let uri5 = `dtlKwtpage/${item.refund_id}/${this.custid2}`;
        axios.get(uri5).then(response => {
          this.dokumenKwt = response.data.data;
        });
        let uri = `getcustnumberandname/${this.custid2}`;
        axios.get(uri).then(response => {
          this.customer = response.data.data;
        });
        let uri2 = `getkwtarray/${item.refund_id}/${this.custid2}`;
        axios.get(uri2).then(response => {
          this.dokumenKwtarray = response.data.data;
          try {
            this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          this.data = this.dokumenKwtarray
          this.data.forEach(item => {
            this.dokumenKwtarray3.push({
              bupot_number: item.bupot_number,
              bupot_date: item.bupot_date,
              dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
              pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
              kwt_id: item.kwt_id,
              kwt_number: item.kwt_number,
              kwt_date: this.formatDate(item.kwt_date),
              dpp_amount: this.formatCurrency2(item.dpp_amount),
              ppn_amount: this.formatCurrency2(item.ppn_amount),
              pph_amount: this.formatCurrency2(item.pph_amount)
            })
          })
          this.dokumenKwtarray.forEach(item => {
            sum = sum + parseInt(item.dpp_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumb = sumb + parseInt(item.ppn_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumc = sumc + parseInt(item.pph_amount);
          })
          sum = this.formatCurrency2(sum);
          sumb = this.formatCurrency2(sumb);
          sumc = this.formatCurrency2(sumc);
          sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
          sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
          try {
            this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          var raw = this.dokumenKwtarray3
          var body = []
          var bupotIdonly = []
          var newIdbupotonly = []
          var index = 1;
          var count = 0;
          var counts = [];
          var indikator = 1;
          var activeBupot = "";
          raw.forEach(item => [
            bupotIdonly.push({
              bupotNumber: item[0]
            })
          ])
          newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
          newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
          var j = 0;
          for (i = 0; i < raw.length; i++) {
            var row = []
            var nextRow = []
            for (var key in raw[i]) {
              row.push(raw[i][key])
            }
            for (var key2 in raw[i + 1]) {
              nextRow.push(raw[i + 1][key2])
            }
            if (activeBupot != row[0]) {
              indikator = 1;
            }
            if (indikator === 1) {
              activeBupot = row[0];
              indikator = 0;
              count = counts[activeBupot];
              j = 0;
            }
            if (j % count === 0) {
              var no_bupot = row[0];
              var tanggal_bupot = row[1];
              var total_dpp_bupot = row[2];
              var total_pph_bupot = row[3];
              row.shift();
              row.shift();
              row.shift();
              row.shift();
              row.unshift({
                rowSpan: count,
                content: total_pph_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: total_dpp_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: tanggal_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: no_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: index,
                styles: { valign: 'middle', halign: 'center' },
              })
              j = j + 1;
              index = index + 1;
            }
            else {
              row.shift();
              row.shift();
              row.shift();
              row.shift();
              j = j + 1
            }
            body.push(row);
          }
          const img = document.querySelector('img#barcode3');
          var cust_name = this.customer.map(({ customer_name }) => customer_name)
          var cust_number = this.customer.map(({ customer_number }) => customer_number)
          var header = cust_number + ' - ' + cust_name;
          // doc.text(header, 13, 5, { baseline: 'middle' });
          var doc = new jsPDF();
          doc.text("Nomor Refund", 105, 15, { baseline: 'middle', align: 'center' });
          doc.setFontSize(10);
          doc.addImage(img.src, 'JPEG', 55, 25, 100, 25);
          doc.text(item.refund_number + ' / ' + this.formatDate(item.refund_date), 85, 23, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(10);
          doc.text("Customer", 166, 10, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(10);
          doc.text(header, 166, 15, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.autoTable({
            columnStyles: {
              0: { cellWidth: 8 },
              1: { cellWidth: 30, fontStyle: 'bold', fontSize: 9 },
              2: { halign: 'center', cellWidth: 20, fontSize: 9 },
              3: { cellWidth: 20, fontSize: 9, halign: 'right' },
              4: { cellWidth: 20, fontSize: 9, halign: 'right' },
              5: { halign: 'center', cellWidth: 30, fontSize: 9, fontStyle: 'bold' },
              6: { halign: 'center', fontSize: 9, cellWidth: 20 },
              7: { halign: 'right', cellWidth: 20, fontSize: 9 },
              8: { halign: 'right', cellWidth: 20, fontSize: 9 },
              9: { halign: 'right', cellWidth: 20, fontSize: 9 },
              10: { halign: 'right', cellWidth: 20, fontSize: 9 },
            },
            bodyStyles: { lineColor: [0, 0, 0] },
            headStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            footStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            theme: 'grid',
            head: [[
              { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Bupot ', colSpan: 4, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Kwitansi', colSpan: 5, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }
            ], [
              { content: 'Nomor', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tanggal', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Nomor', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tanggal', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPN', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }
            ],
            ],
            body: body,
            margin: { top: 55, left: 1, right: 1 },
            foot: [[
              { content: 'Grand total Bupot', colSpan: 3, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: sumTotalDppRefund, colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumTotalPPhRefund, colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: 'Grand total Kwt', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumb, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } }
            ]]
          })
          doc.autoTable({
            bodyStyles: { lineColor: [0, 0, 0] },
            headStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0]
            },
            theme: 'grid',
            head: [[{ content: 'Validation Notes', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }]],
            body: [
              ['Tgl. Validasi Refund:  ', '                          '],
              ['Tgl. Cetak  Refund:  ', today],
              ['Tgl. Kembali:  ', '                          '],
              ['Paraf Petugas Validasi:  ', '                          '],
              ['Nama Petugas:  ', '                          '],
            ],
            margin: { left: 135 },
            startY: 215,
            lineColor: [0, 0, 0]
          })
          // doc.setFontSize(35)
          // //doc.setTextColor(230);
          // doc.setTextColor(245, 245, 245, 0.33);
          // doc.setFont("Boogaloo");
          // doc.text("Watermark", 60, 200, null, 45);
          // doc.output('dataurlnewwindow')
          doc.saveGraphicsState();
          doc.setGState(new doc.GState({ opacity: 0.2 }));
          doc.setFontSize(100)
          doc.setTextColor(255, 0, 0);
          doc.text("REJECT", 65, 200, null, 45)
          doc.restoreGraphicsState();
          doc.setProperties({
            title: "Laporan Bukti Potong"
          });
          window.open(doc.output('bloburl'))
        });
      });
      this.dokumenKwtarray2.splice(0);
    },
    showPdfCancel(item) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + '-' + mm + '-' + yyyy;
      JsBarcode("#barcode3", item.refund_number);
      this.dokumenKwtarray3.splice(0);
      var sum = 0;
      var sumb = 0;
      var sumc = 0;
      var i = 1;
      var sumTotalDppRefund = 0;
      var sumTotalPPhRefund = 0;
      let uri4 = `dtltrxpage/${item.refund_id}`;
      axios.get(uri4).then(response => {
        this.dokumenBupot = response.data.data;
        this.custid2 = this.dokumenBupot;
        let uri5 = `dtlKwtpage/${item.refund_id}/${this.custid2}`;
        axios.get(uri5).then(response => {
          this.dokumenKwt = response.data.data;
        });
        let uri = `getcustnumberandname/${this.custid2}`;
        axios.get(uri).then(response => {
          this.customer = response.data.data;
        });
        let uri2 = `getkwtarray/${item.refund_id}/${this.custid2}`;
        axios.get(uri2).then(response => {
          this.dokumenKwtarray = response.data.data;
          try {
            this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          this.data = this.dokumenKwtarray
          this.data.forEach(item => {
            this.dokumenKwtarray3.push({
              bupot_number: item.bupot_number,
              bupot_date: item.bupot_date,
              dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
              pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
              kwt_id: item.kwt_id,
              kwt_number: item.kwt_number,
              kwt_date: this.formatDate(item.kwt_date),
              dpp_amount: this.formatCurrency2(item.dpp_amount),
              ppn_amount: this.formatCurrency2(item.ppn_amount),
              pph_amount: this.formatCurrency2(item.pph_amount)
            })
          })
          this.dokumenKwtarray.forEach(item => {
            sum = sum + parseInt(item.dpp_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumb = sumb + parseInt(item.ppn_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumc = sumc + parseInt(item.pph_amount);
          })
          sum = this.formatCurrency2(sum);
          sumb = this.formatCurrency2(sumb);
          sumc = this.formatCurrency2(sumc);
          sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
          sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
          try {
            this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          var raw = this.dokumenKwtarray3
          var body = []
          var bupotIdonly = []
          var newIdbupotonly = []
          var index = 1;
          var count = 0;
          var counts = [];
          var indikator = 1;
          var activeBupot = "";
          raw.forEach(item => [
            bupotIdonly.push({
              bupotNumber: item[0]
            })
          ])
          newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
          newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
          var j = 0;
          for (i = 0; i < raw.length; i++) {
            var row = []
            var nextRow = []
            for (var key in raw[i]) {
              row.push(raw[i][key])
            }
            for (var key2 in raw[i + 1]) {
              nextRow.push(raw[i + 1][key2])
            }
            if (activeBupot != row[0]) {
              indikator = 1;
            }
            if (indikator === 1) {
              activeBupot = row[0];
              indikator = 0;
              count = counts[activeBupot];
              j = 0;
            }
            if (j % count === 0) {
              var no_bupot = row[0];
              var tanggal_bupot = row[1];
              var total_dpp_bupot = row[2];
              var total_pph_bupot = row[3];
              row.shift();
              row.shift();
              row.shift();
              row.shift();
              row.unshift({
                rowSpan: count,
                content: total_pph_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: total_dpp_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: tanggal_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: no_bupot,
                styles: { valign: 'middle', halign: 'center' },
              })
              row.unshift({
                rowSpan: count,
                content: index,
                styles: { valign: 'middle', halign: 'center' },
              })
              j = j + 1;
              index = index + 1;
            }
            else {
              row.shift();
              row.shift();
              row.shift();
              row.shift();
              j = j + 1
            }
            body.push(row);
          }
          const img = document.querySelector('img#barcode3');
          var cust_name = this.customer.map(({ customer_name }) => customer_name)
          var cust_number = this.customer.map(({ customer_number }) => customer_number)
          var header = cust_number + ' - ' + cust_name;
          // doc.text(header, 13, 5, { baseline: 'middle' });
          var doc = new jsPDF();
          doc.text("Nomor Refund", 105, 15, { baseline: 'middle', align: 'center' });
          doc.setFontSize(10);
          doc.addImage(img.src, 'JPEG', 55, 25, 100, 25);
          doc.text(item.refund_number + ' / ' + this.formatDate(item.refund_date), 85, 23, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(10);
          doc.text("Customer", 166, 10, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(10);
          doc.text(header, 166, 15, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.autoTable({
            columnStyles: {
              0: { cellWidth: 8 },
              1: { cellWidth: 30, fontStyle: 'bold', fontSize: 9 },
              2: { halign: 'center', cellWidth: 20, fontSize: 9 },
              3: { cellWidth: 20, fontSize: 9, halign: 'right' },
              4: { cellWidth: 20, fontSize: 9, halign: 'right' },
              5: { halign: 'center', cellWidth: 30, fontSize: 9, fontStyle: 'bold' },
              6: { halign: 'center', fontSize: 9, cellWidth: 20 },
              7: { halign: 'right', cellWidth: 20, fontSize: 9 },
              8: { halign: 'right', cellWidth: 20, fontSize: 9 },
              9: { halign: 'right', cellWidth: 20, fontSize: 9 },
              10: { halign: 'right', cellWidth: 20, fontSize: 9 },
            },
            bodyStyles: { lineColor: [0, 0, 0] },
            headStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            footStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            theme: 'grid',
            head: [[
              { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Bupot ', colSpan: 4, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Kwitansi', colSpan: 5, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }
            ], [
              { content: 'Nomor', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tanggal', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Nomor', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tanggal', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPN', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }
            ],
            ],
            body: body,
            margin: { top: 55, left: 1, right: 1 },
            foot: [[
              { content: 'Grand total Bupot', colSpan: 3, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: sumTotalDppRefund, colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumTotalPPhRefund, colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: 'Grand total Kwt', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumb, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } }
            ]]
          })
          doc.autoTable({
            bodyStyles: { lineColor: [0, 0, 0] },
            headStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0]
            },
            theme: 'grid',
            head: [[{ content: 'Validation Notes', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }]],
            body: [
              ['Tgl. Validasi Refund:  ', '                          '],
              ['Tgl. Cetak  Refund:  ', today],
              ['Tgl. Kembali:  ', '                          '],
              ['Paraf Petugas Validasi:  ', '                          '],
              ['Nama Petugas:  ', '                          '],
            ],
            margin: { left: 135 },
            startY: 215,
            lineColor: [0, 0, 0]
          })
          // doc.setFontSize(35)
          // //doc.setTextColor(230);
          // doc.setTextColor(245, 245, 245, 0.33);
          // doc.setFont("Boogaloo");
          // doc.text("Watermark", 60, 200, null, 45);
          // doc.output('dataurlnewwindow')
          doc.saveGraphicsState();
          doc.setGState(new doc.GState({ opacity: 0.2 }));
          doc.setFontSize(100)
          doc.setTextColor(255, 0, 0);
          doc.text("CANCEL", 65, 200, null, 45)
          doc.restoreGraphicsState();
          doc.setProperties({
            title: "Laporan Bukti Potong"
          });
          window.open(doc.output('bloburl'))
        });
      });
      this.dokumenKwtarray2.splice(0);
    },
    formatCurrency2(value) {
      var formatter = new Intl.NumberFormat('en-US', {
      });

      return formatter.format(value); /* $2,500.00 */
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    formatDate2(value) {
      return moment(value).format("DD-MMM-YYYY");
    },
    getKwtValue(item) {
      var data = [item.bupot_number, item.bupot_date, item.dpp_amount_bupot, item.pph_amount_bupot, item.kwt_number, item.kwt_date, item.dpp_amount, item.ppn_amount, item.pph_amount, item.tarif];
      return data;
    },
    getBupotNumber(item) {
      var data = [item.bupotNumber];
      return data;
    },
    closeDialog() {
      this.dialogDelete = false
      this.dokumenBupot = {}
      this.dokumenKwt = []
    },
    showDialogRegister() {
      this.dialogDownloadRegister = true;
    },
    cetakReportRegister() {
      this.overlay = true;
      axios({
        method: 'post',
        url: '/downloadReportRegister',
        data: {
          start_date: this.date,
          end_date: this.date_end
        },
        responseType: "blob"
      })
        .then((result) => {
          const contentDisposition = result.headers['content-disposition'];

          var fileName = contentDisposition.split(";");
          fileName = fileName[1].replace(' filename=', '');
          // console.log(fileName)
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.dialogDownloadRegister = false;
          this.date = '';
          this.date_end = '';
          this.overlay = false;
        })
    }
  },
  components: {
    DatePicker
  }
}
</script>